import React from 'react';
import { useTranslation } from 'react-i18next';

import LocalizedLink from './localized-link';
import Image from './image';

import './duty-free-district-announcement.scss';

const DutyFreeDistrictAnnouncement = () => {
  const { t } = useTranslation('DutyFreeDistrictPage');

  return (
    <LocalizedLink to="/duty-free-district">
      <aside className="st-c-duty-free-district-announcement">
        <div className="columns is-vcentered">
          <div className="column is-narrow">
            <Image
              filename="duty-free-district-logo-small.png"
              className="st-c-duty-free-district-announcement__logo"
            ></Image>
          </div>
          <div className="column">
            <h5 className="st-c-duty-free-district-announcement__title">
              {t('announcement.title')}
            </h5>
            <p>{t('announcement.description')}</p>
          </div>
        </div>
      </aside>
    </LocalizedLink>
  );
};

export default DutyFreeDistrictAnnouncement;
