import React from 'react';

import { Link } from 'react-scroll';

const SmoothScroll = ({ to, children, ...props }) => (
  <Link href={`#${to}`} to={to} smooth={true} offset={-80} duration={500} {...props}>
    {children}
  </Link>
);

export default SmoothScroll;
