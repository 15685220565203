import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

const LanguageSpecific = ({ includeLangs, children }) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  if (includeLangs && !includeLangs.includes(currentLang)) {
    return null;
  }

  return children;
};

LanguageSpecific.propTypes = {
  children: PropTypes.node.isRequired,
  includeLangs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LanguageSpecific;
