import React from 'react';
import Image from '../components/image';
import { useTranslation } from 'react-i18next';

import './business-benefits.scss';

const BusinessBenefits = () => {
  const { t } = useTranslation('BusinessPage');

  return (
    <>
      <div className="st-business-benefits__benefit">
        <div className=" columns is-vcentered">
          <div className="column is-narrow">
            <Image
              filename="illustrations/attract-customers.png"
              className="st-p-business__benefit-image"
            ></Image>
          </div>
          <div className="column is-four-fifths">
            <h3 className="st-business-benefits__title">{t('benefits.dutyFreeStore.title')}</h3>
            <p className="subtitle is-5">{t('benefits.dutyFreeStore.paragraph')}</p>
          </div>
        </div>
      </div>

      <div className="st-business-benefits__benefit">
        <div className=" columns is-vcentered">
          <div className="column is-narrow">
            <Image
              filename="illustrations/boost-sales.png"
              className="st-p-business__benefit-image"
            ></Image>
          </div>
          <div className="column is-four-fifths">
            <h3 className="st-business-benefits__title">{t('benefits.attractCustomers.title')}</h3>
            <p className="subtitle is-5">{t('benefits.attractCustomers.paragraph')}</p>
          </div>
        </div>
      </div>

      <div className="st-business-benefits__benefit">
        <div className=" columns is-vcentered">
          <div className="column is-narrow">
            <Image
              filename="illustrations/no-bureaucracy.png"
              className="st-p-business__benefit-image"
            ></Image>
          </div>
          <div className="column is-four-fifths">
            <h3 className="st-business-benefits__title">{t('regulations.title')}</h3>
            <p className="subtitle is-5">{t('regulations.paragraph')}</p>
          </div>
        </div>
      </div>

      <div className="st-business-benefits__benefit">
        <div className=" columns is-vcentered">
          <div className="column is-narrow">
            <Image
              filename="illustrations/fully-digital.png"
              className="st-p-business__benefit-image"
            ></Image>
          </div>
          <div className="column is-four-fifths">
            <h3 className="st-business-benefits__title">{t('benefits.experience.title')}</h3>
            <p className="subtitle is-5">{t('benefits.experience.description')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessBenefits;
